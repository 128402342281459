import { useRef } from 'react'
import './contact-form.scss'
import emailjs from '@emailjs/browser'

const ContactForm = () => {
  const formRef = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'Makina_Vending',
        'Makina_Vending_Template',
        formRef.current,
        '4bj9E2aSSTOSVrzHX'
      )
      .then(
        () => {
          alert('Message successfully sent!')
          window.location.reload(false)
        },
        () => {
          alert('Failed to send message. Please try again.')
        }
      )
  }

  return (
    <form
      className='contact-form'
      ref={formRef}
      onSubmit={sendEmail}
    >
      <ul className='contact-list'>
        <li>
          <input
            aria-label='Enter your name'
            aria-required='true'
            type='text'
            name='user-name'
            placeholder='Name*'
            required
          />
        </li>
        <li>
          <input
            aria-label='Enter your email'
            aria-required='true'
            type='email'
            name='user-email'
            placeholder='Email*'
            required
          />
        </li>
        <li>
          <input
            aria-label='Enter your phone number'
            aria-required='true'
            type='tel'
            name='user-phone-number'
            placeholder='Phone Number* 012-345-6789'
            pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
            required
          />
        </li>
        <li>
          <input
            aria-label='Enter your message subject'
            aria-required='false'
            type='text'
            name='user-subject'
            placeholder='Subject'
          />
        </li>
        <li>
          <textarea
            aria-label='Enter your message'
            aria-required='true'
            name='user-message'
            placeholder='Message*'
            required
          />
        </li>
        <li>
          <div className='contact-button-container'>
            <button
              type='submit'
              className='submit-button'
            >
              <span className='submit-button-content'>SEND</span>
            </button>
          </div>
        </li>
      </ul>
    </form>
  )
}

export default ContactForm

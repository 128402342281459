import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './home.scss'
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons'
import FullLogoStatic from '../AddOns/SVGs/full-logo-static'
import ContactForm from './ContactForm/contact-form'

const Home = () => {
  return (
    <div className='main-container'>
      <div className='main-content'>
        <div className='logo-container'>
          <FullLogoStatic />
        </div>
        <p className='tagline'>Snack Your Way</p>
        <h1 className='main-header'>WEBSITE UNDER CONSTRUCTION</h1>
        <div className='construction-icon-container'>
          <FontAwesomeIcon
            className='construction-icon'
            icon={faScrewdriverWrench}
          />
        </div>
        <h2 className='main-header'>Contact Us</h2>
        <div className='contact-wrapper'>
          <ContactForm />
        </div>
        <p className='advert-header rotated-header rotated-header-left'>
          Service is available! Free machine installation!
        </p>
        <p className='advert-header rotated-header rotated-header-right'>
          All machines come with QR codes to suggest snacks!
        </p>
      </div>
    </div>
  )
}

export default Home
